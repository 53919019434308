<template>
  <div class="format-form">
    <div>
      <el-input
        @change="handleServeTimeChange"
        size="small"
        v-model="ruleForm.serveTimeLength"
        placeholder="服务时长"
      >
        <template #suffix>| 分钟</template>
      </el-input>
    </div>
    <!-- <div>
      <el-select size="small" v-model="ruleForm.jobLvId" class="m-2" placeholder="请选择职级">
        <el-option v-for="item in rankList" :key="item.value" :label="item.levelName" :value="item.lvId" />
      </el-select>
    </div> -->
    <!-- <div>
      <el-input size="small" v-model="ruleForm.paraText" placeholder="规格说明" />
    </div> -->
    <div>
      <el-input size="small" v-model="ruleForm.salePrice" placeholder="原价">
        <template #suffix>| 元</template>
      </el-input>
    </div>

    <div class="promPrice">
      <el-input size="small" v-model="ruleForm.promPrice" placeholder="划线价">
        <template #suffix>| 元</template>
      </el-input>
      <!-- <el-button color="#409EFF" size="small" v-if="isFirst" @click="handleAddClick" class="add" type="primary" plain>新增
      </el-button> -->
      <!-- <el-button size="small" @click="handleRemoveClick" class="remove" type="danger" plain>删除</el-button> -->
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { ElMessage } from 'element-plus'
import { formatListOption } from './config'
export default defineComponent({
  props: {
    isFirst: {
      type: Boolean,
      default: false
    },
    ruleForm: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  emits: ['addFormatFormChange', 'removeFormatFormChange'],
  setup(props, { emit }) {
    const store = useStore()
    const handleAddClick = () => {
      emit('addFormatFormChange')
    }

    const handleRemoveClick = () => {
      emit('removeFormatFormChange')
    }

    const initPage = () => {
      store.dispatch('serve/getRankListAction', {
        companyId: COMPANY_ID
      })
    }
    initPage()
    // const rankList = computed(() => store.state.serve.rankList)

    const handleServeTimeChange = time => {
      if (Number(time) % 5 != 0) {
        ElMessage({
          message: '服务时长必须是5的倍数!',
          type: 'warning',
        })
        props.ruleForm.serveTimeLength = ''
      }

      if (Number(time) <= 0) {
        ElMessage({
          message: '输入的服务时长不合法!',
          type: 'warning',
        })
        props.ruleForm.serveTimeLength = ''
      }
    }


    return {
      handleServeTimeChange,
      handleAddClick,
      handleRemoveClick,
      COMPANY_ID,
      // rankList,
      formatListOption
    }

  }
})
</script>

<style scoped lang="less">
.format-form {
  max-width: 1000px;
  min-width: 400px;
  display: flex;
  margin: 16px 0;
  padding: 0 10px;

  .promPrice {
    flex: 1.3;

    &:deep(.el-button) {
      margin-left: 10px;
    }
  }

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  &:deep(.el-select) {
    max-width: 150px;
  }

  &:deep(.el-input) {
    max-width: 150px;
  }

  & > div {
    flex: 1;
  }

  .promPrice {
    display: flex;
    align-items: center;
  }
}
</style>
