<template>
  <div class="add-form">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="addServeRules"
      label-width="120px"
      class="demo-ruleForm"
      :size="formSize"
    >
      <el-form-item class="form-input" label="名称：" prop="serveName">
        <el-input v-model="ruleForm.serveName"></el-input>
      </el-form-item>

      <el-form-item class="form-input" label="分类：" prop="serveKind">
        <el-select v-model="ruleForm.serveKind" class="m-2" placeholder="请选择">
          <el-option
            v-for="item in serveCategoryList"
            :key="item.value"
            :label="item.kindName"
            :value="item.kindId"
          >
          </el-option>
        </el-select>
        <span @click="handleGoodsCategoryClick" class="other">管理服务分类</span>
      </el-form-item>

      <el-form-item class="form-input" label="标签：" prop="serveTags">
        <el-select multiple v-model="ruleForm.serveTags" class="m-2" placeholder="请选择">
          <el-option
            v-for="item in serveTagList"
            :key="item.tagName"
            :label="item.tagName"
            :value="item.tagName"
          >
          </el-option>
        </el-select>
        <span @click="handleGoodsTagClick" class="other">管理服务标签</span>
      </el-form-item>

      <el-form-item label="图片：" prop="desc">
        <upload-img
          :isAdd="true"
          :limit="1"
          @handleUploadRemove="handleUploadRemove"
          @handleAddImgUploadRemove="handleAddImgUploadRemove"
          ref="imgUploadRef"
          @handleUploadEmpty="handleUploadEmpty"
          @handleUploadSuccess="handleImgUploadSuccess"
        ></upload-img>
        <div class="tips">建议尺寸：800*600像素，最多上传1张</div>
      </el-form-item>
      <el-form-item class="form-input" label="服务时长：" prop="serveLength">
        <el-input-number v-model="ruleForm.serveLength" :controls="false" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item class="form-input" label="筹备时长：" prop="prepare">
        <el-input-number v-model="ruleForm.prepare" :controls="false" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item class="form-input" label="价格：" prop="price">
        <el-input-number v-model="ruleForm.price" :controls="false" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label-width="160px" label="小程序/网店展示:" prop="displayApplet">
        <div class="expire-operation">
          <div>
            <el-radio v-model="ruleForm.displayApplet" :label="true" size="large">展示</el-radio>
            <el-radio v-model="ruleForm.displayApplet" :label="false" size="large">不展示</el-radio>
          </div>
        </div>
      </el-form-item>

      <!-- <el-form-item label="视频：" prop="desc">
        <upload-video :file-list="videoFileList" ref="videoUploadRef" @handleUploadSuccess="handleVideoUploadSuccess"
          @handleUploadRemove="handleVideoUploadRemove"></upload-video>
        <div class="tips">建议上传视频时长为9-30秒，视频宽高比为16:9</div>
      </el-form-item> -->

      <!-- <el-form-item label="规格：" prop="desc">
        <div class="format">
          <div class="titles">
            <div>服务时间</div>
            <div>原价</div>
            <div class="huaxian-price">划线价</div>
          </div>
          <template :key="item" v-for="(item, index) in formatForm">
            <format-form
              :isFirst="index == 0"
              @removeFormatFormChange="hadleRemoveFormatFormChange(index)"
              @addFormatFormChange="handleAddFormatFormChange"
              :ruleForm="formatForm[index]"
            ></format-form>
          </template>
        </div>
      </el-form-item> -->
      <el-form-item v-show="false" label="线上预约" prop="servePayFlag">
        <div class="online-reserve">
          <el-radio disabled v-model="ruleForm.servePayFlag" label="0" size="large"
            >无需支付</el-radio
          >
          <el-radio disabled v-model="ruleForm.servePayFlag" label="2" size="large">
            <span>需支付</span>
            <el-select
              disabled
              v-model="ruleForm.servePayKind"
              class="m-2"
              placeholder="按百分比"
              size="large"
            >
              <el-option
                v-for="item in payOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input disabled v-model="ruleForm.reservePayAmount">
              <template #suffix>
                <span v-if="ruleForm.servePayKind == '2'">元</span>
                <span v-else>%</span>
              </template>
            </el-input>
          </el-radio>
        </div>
      </el-form-item>
      <div class="mobile-preview">
        <div class="top">
          <div class="left">
            <div class="top-info">
              <img src="~assets/img/card/mobile-top.png" alt />
            </div>

            <div v-if="String(contentHtml).length <=11" class="tips">
              <div>基本信息区</div>
              <div>固定样式，显示商品主图、价格等信息</div>
            </div>
            <div class="content">
              <el-scrollbar class="scrollbar" :height="{ height: String(contentHtml).length <=11 ? '506px' : '170px' }">
                <div v-html="contentHtml" :style="{ height: String(contentHtml).length >11? '576px' : '170px' }"
                  class="goods-detail"></div>
              </el-scrollbar>
            </div>
          </div>
          <div class="right">
            <div class="right-top">
              <div class="title">
                <span>分享文案</span>
              </div>
              <div class="el-input">
                <el-input v-model="copywritingValue" show-word-limit :rows="2" type="textarea" :maxlength="32" />
              </div>
            </div>
            <div class="right-bottom">
              <div class="title">
                <span>商品详情</span>
              </div>
              <page-md-editor class="md-editor" @onTextChanged="onTextChanged" @onHtmlChanged="onHtmlChanged">
              </page-md-editor>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="bottom">
          <div class="left">
            <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
          </div>
          <div class="right">
          </div>
        </div>
      </div>
    </el-form>
    <add-serve-category-dialog
      :dialogVisible="addCategoryDialogVisible"
      @changeDialogVisible="changeAddCategoryDialog"
    >
    </add-serve-category-dialog>
    <add-tag-dialog
      :dialogVisible="addTagDialogVisible"
      @changeDialogVisible="changeAddTagDialog"
    ></add-tag-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import pageUpload from '@/components/page-upload/page-upload.vue'
import { serveTag, serveCategory, serveTimeLength, serveJobLvId, payOptions } from './config'
import AddServeCategoryDialog from './cpns/add-serve-category-dialog/add-serve-category-dialog.vue'
import AddTagDialog from './cpns/add-tag-dialog/add-tag-dialog.vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import UploadVideo from '@/components/page-upload/upload-video/upload-video.vue'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { COMPANY_ID, SERVE_DEFAULT_IMG } from '@/common/constant'
import FormatForm from './cpns/format-form/format-form'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import { useRoute } from 'vue-router'
import router from '@/router'
import { addServeRules } from '../../../../config'
import { ElMessage } from 'element-plus'
import PageMdEditor from '@/components/page-md-editor/page-md-editor.vue'
import {addServe} from '@/service/main/serve'
export default defineComponent({
  props: {
    serveId: {
      type: String,
      default: ''
    },
  },
  components: {
    pageUpload,
    AddServeCategoryDialog,
    AddTagDialog,
    UploadImg,
    UploadVideo,
    FormatForm,
    PageMdEditor
  },
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const ruleForm = reactive({
      // serveId: "",
      serveName: "",
      serveKind: "",
      // companyId: COMPANY_ID,
      // serveVideo: "",
      // servePayFlag: "2",
      // servePayKind: "1",
      // reservePayAmount: "100",
      picture:"",
      serveTags: [],
      serveLength:null,
      prepare:null,
      price:null,
      displayApplet:null

    })
    const ruleFormRef = ref()
    const emptyForm = () => {
      for (let key in ruleForm) {
        if (key != 'companyId') {
          ruleForm[key] = ''
        }
      }
    }

    const imgUploadRef = ref(null)
    const videoFileList = ref([])
    const videoUploadRef = ref('')
    const addCategoryDialogVisible = ref(false)
    const addTagDialogVisible = ref(false)

    const formatForm = ref([
      {
        serveTimeLength: '',
        jobLvId: '',
        salePrice: '',
        promPrice: '',
        paraText: '默认'
      }
    ])

    const rankList = computed(() => store.state.serve.rankList)
    const format = function (f) {
      const m = Math.pow(10, 0);
      return Math.round(f * m) / m;
    };
    const handleUploadRemove=url=>{
      let index=serverImgList.value.findIndex(ele=>ele==url)
      if(index!=-1){
        serverImgList.value.splice(index,1)
      }
    }

    const handlePicUploadSuccess = (res) => {
      ruleForm.pic = res.response.data.fileName
    }

    const handleVidUploadSuccess = (res) => {
      ruleForm.serveVideo = res.response.data.fileName
    }

    const changeAddCategoryDialog = (flag) => {
      addCategoryDialogVisible.value = flag
    }

    const changeAddTagDialog = (flag) => {
      addTagDialogVisible.value = flag
    }

    const handleGoodsCategoryClick = () => {
      addCategoryDialogVisible.value = true
    }

    const handleGoodsTagClick = () => {
      addTagDialogVisible.value = true
    }
    const serverImgList = ref([])
    const handleImgUploadSuccess = (res) => {
      serverImgList.value.push(res.response.data.fileName)
    }

    const handleVideoUploadSuccess = (res) => {
      ruleForm.serveVideo = res.response.data.fileName

      videoFileList.value.push({
        name: 'video',
        url: `${UPLOAD_BASE_URL}/${res.response.data.fileName}`
      })
    }

    const handleVideoUploadRemove = (url) => {
      ruleForm.serveVideo = ''
      const index = videoFileList.value.findIndex(item => {
        return item.url == url
      })
      if (index != -1) {
        videoFileList.value.splice(index, 1)
      }
    }

    const handleAddImgUploadRemove = url => {
      let index = serverImgList.value.findIndex(item => item == url)
      if (index != -1) {
        serverImgList.value.splice(index, 1)
      }
      // console.log(url);
      // console.log(serverImgList.value);
    }

    const handleImgUploadRemove = url => {
      // console.log(url);
    }

    const handleUploadEmpty = () => {
      // console.log('清空');
    }


    const hadleRemoveFormatFormChange = (index) => {
      formatForm.value.splice(index, 1)
    }

    const handleAddFormatFormChange = () => {
      formatForm.value.push({
        serveTimeLength: '',
        jobLvId: '',
        salePrice: '',
        promPrice: '',
      })
    }

    const addFormState = useState('serve', ['serveTagList', 'serveCategoryList'])
    const initPage = () => {
      store.dispatch('serve/getServeCategoryList', { companyId: COMPANY_ID })
      store.dispatch('serve/getServeTagAction', { companyId: COMPANY_ID, tagKind: 1 })
    }
    initPage()

    const uploadShopImgRef = ref('')
    watch(() => route.path, (n) => {
      if (n == '/main/serve/add') {
        imgUploadRef.value && imgUploadRef.value.emptyFileList()
        videoUploadRef.value && videoUploadRef.value.emptyFileList()
        emptyForm()
        videoFileList.value = []
      }
    })

    const contentText = ref('')
    const contentHtml = ref('')
    const onHtmlChanged = (value) => {
      contentHtml.value = value
    }
    const copywritingValue = ref('这个商品很不错，推荐你也买一个')
    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          let flag = true

          if (flag) {
            // 如果没上传图片 设置默认图片
            if (serverImgList.value.length == 0) {
              serverImgList.value=[SERVE_DEFAULT_IMG]
            }
            let obj = {
              ...ruleForm,
              serveId: props.serveId?props.serveId:undefined,
              serveTags:ruleForm.serveTags.join(','),
              picture: serverImgList.value.join(',')
            }
              addServe({...obj,shareWords: copywritingValue.value,serveInfo: contentHtml.value}).then(res=>{
                if (res.code == 0) {
                  router.push('/main/serve/list')
                }else{
                  ElMessage({
                    message: res.msg,
                    type: 'warning',
                  })
                }
              })
          }
        } else {
          ElMessage({
            message: '请正确填写数据',
            type: 'warning',
          })
        }
      })

    }


    const onTextChanged = (value) => {
      contentText.value = value
      emit('onTextChanged', value)
    }

    return {
      handleUploadRemove,
      rankList,
      handleAddImgUploadRemove,
      handleUploadEmpty,
      handleImgUploadRemove,
      addServeRules,
      ruleFormRef,
      uploadShopImgRef,
      ruleForm,
      handlePicUploadSuccess,
      serveTag,
      serveCategory,
      ...addFormState,
      handleVidUploadSuccess,
      formatForm,
      serveTimeLength,
      serveJobLvId,
      addCategoryDialogVisible,
      changeAddCategoryDialog,
      addTagDialogVisible,
      changeAddTagDialog,
      handleGoodsCategoryClick,
      handleGoodsTagClick,
      imgUploadRef,
      serverImgList,
      handleImgUploadSuccess,
      videoFileList,
      videoUploadRef,
      handleVideoUploadSuccess,
      handleVideoUploadRemove,
      hadleRemoveFormatFormChange,
      handleAddFormatFormChange,
      payOptions,
      onTextChanged,
      contentText,
      onHtmlChanged,
      handleSaveClick,
      copywritingValue,
      contentHtml
    }

  }
})
</script>

<style scoped lang="less">
.add-form {
  .online-reserve {
    &:deep(.el-input) {
      max-width: 120px;
      margin-left: 10px;
    }
  }
  &:deep(.el-input-number .el-input__inner) {
    text-align: left !important;
  }

  .tips {
    color: rgba(128, 128, 128, 1);
    font-size: 12px;
  }

  .next-btn {
    margin-top: 40px;
    width: 156px;
    height: 48px;
  }

  .form-input {
    .other {
      display: inline-block;
      margin-left: 16px;
      color: rgba(42, 130, 228, 1);
      font-size: 12px;
      cursor: pointer;
    }

    &:deep(.el-input, .el-select) {
      width: 30%;
      max-width: 400px;
      min-width: 260px;
    }

    &:deep(.el-select) {
      width: 30%;
      max-width: 400px;
      min-width: 160px;

      .el-input {
        width: 100%;
      }
    }
  }

  .format {
    max-width: 1000px;
    min-width: 400px;

    .titles {
      display: flex;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 12px;
      padding: 0 10px;

      .huaxian-price {
        flex: 1.3;
      }

      div {
        flex: 1;
      }
    }

    .format-form {
      .example {
        color: rgba(42, 130, 228, 1);
        font-size: 12px;

        .save-btn {
          width: 44px;
          height: 30px;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          background-color: rgba(42, 130, 228, 1);
          border-radius: 5px;
          text-align: center;
        }
      }

      .top-form,
      .bottom-form {
        display: flex;

        div {
          flex: 1;
        }

        &:deep(.el-input) {
          margin-right: 20px;
        }

        .example {
          display: flex;
        }
      }

      .bottom-form {
        &:deep(.el-select) {
          margin-right: 20px;
        }

        .other {
          display: flex;

          &:deep(.el-input) {
            flex: 1;
          }

          .example {
            max-width: 40px;
          }
        }
      }

      .top-form {
        margin: 16px 0;

        .other {
          display: flex;

          &:deep(.el-input) {
            flex: 1;
          }

          .example {
            max-width: 40px;
          }
        }
      }
    }
  }
}
.mobile-preview {
  padding: 36px 6px 6px 6px;
  min-width: 980px;

  &:deep(.el-input .el-input__count) {
    height: auto !important;

  }

  .el-scrollbar {
    width: 100%;
  }

  .top {
    display: flex;

    .left,
    .right {
      min-width: 340px;
      max-width: 340px;
      height: 660px;
    }

    .left {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      margin-right: 50px;
      background-color: rgb(239, 239, 239);

      .content {
        padding: 8px;
        width: 340px;

        .scrollbar {
          border: 1px solid rgba(255, 141, 26, 0.5);
          background-color: rgba(255, 255, 255, 1);
        }

        .goods-detail {
          width: 100%;
          height: 174px;
          word-wrap: break-word;
          word-break: normal;

          img {
            width: 100%;
          }

          &:deep(p) {
            img {
              width: 100%;
            }
          }

          &:deep(figure) {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 94%;
              margin: 4px 0;
            }
          }
        }
      }

      .top-info {
        height: 66px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .tips {
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {
          text-align: center;
          color: rgba(80, 80, 80, 1);
        }

        &>div:nth-child(1) {
          font-size: 16px;
          margin-bottom: 16px;
        }

        &>div:nth-child(2) {
          font-size: 14px;
        }
      }
    }

    .right {
      .right-top {
        height: 170px;

        .el-input {
          padding: 10px;
          background-color: rgba(239, 239, 239, 1);
        }
      }

      .right-top,
      .right-bottom {
        .title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;
          margin-bottom: 20px;
          padding-left: 14px;
          font-size: 14px;
          box-sizing: border-box;

          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 22px;
            color: rgba(80, 80, 80, 1);
            background-color: rgba(42, 130, 228, 1);
            left: 0;
            top: -2px;
          }
        }
      }

      .right-bottom {
        height: 490px;
        // overflow-y: hidden;

        .md-editor {
          width: 100%;
          padding: 0 10px 10px 10px;
          background-color: rgba(239, 239, 239, 1);
          height: 93%;

          &:deep(.md) {
            height: 432px;
          }

          &:deep(.md-toolbar-wrapper) {
            .md-toolbar-item {
              padding: 0 0;
            }

            .md-toolbar {
              max-width: 340px;
              min-width: 310px;
              flex-wrap: wrap;
              height: auto;
            }

            .md-toolbar-left {
              flex-wrap: wrap;
            }

            .md-toolbar-right {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .bottom {
    width: 720px;
    display: flex;
    justify-content: center;

    &:deep(.el-button) {
      width: 156px;
      height: 48px;
    }
  }
}
</style>
