<template>
  <div v-if="$route.path=='/main/serve/add'" class="add">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="add-content">
        <add-form :serveInfo="serveInfo"></add-form>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import AddForm from './cpns/base-info/cpns/add-form/add-form.vue'
import { breadcrumbList } from './config'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    AddForm
  },
  setup() {
    // const serveId = ref("")
    const serveInfo = ref("")
    const activeName = ref('a')
    emitter.on('addServeNextChange', (v) => {
      activeName.value = 'b'
      // serveId.value = v
      serveInfo.value = v
    })

    emitter.on('addServePreviousChange', () => {
      activeName.value = 'a'
    })

    return {
      // serveId,
      serveInfo,
      breadcrumbList,
      activeName
    }

  }
})
</script>

<style scoped lang="less">
.add {
  min-width: 980px;

  .add-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




